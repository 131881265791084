import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from '../modules/auth';
import { Router } from '@angular/router';
import { LoaderService } from './components/loader/service/loader.service';
import { catchError, finalize, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(
        public router: Router,
        public loaderService: LoaderService,
        private authService: AuthService,
        private toastr: ToastrService
    ) { }

    timer: NodeJS.Timeout;

    fullUrl: any;
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url

        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => this.loaderService.show(), 1000);

        const parsedUrl = new URL(window.location.href);
        const host = parsedUrl.hostname == 'www.cybase.ae' ? 'cybase' : parsedUrl.hostname.split('.')[0];

        /*************** Production *****************/
        // const baseUrl = 'https://api.cybase.ae/'+ host  ;
        // const baseUrl = 'https://api.cybase.qa/'+ host  ;
        // const baseUrl = 'https://api.cybasecanada.com/'+ host;
        //  const baseUrl = 'https://api.cybasebahrain.com/'+ host;
        // const baseUrl = 'https://api.cybaseusa.com/'+ host;

        // const baseUrl = 'https://api.cybase.qa/'+ 'cybaseqatar';
        // const baseUrl = 'https://api.cybasecanada.com/'+ 'cybasecanada'  ;
        // const baseUrl = 'https://api.cybase.ae/'+ 'cybaseuae';
        // const baseUrl = 'https://api.cybasebahrain.com/'+ 'cybasebahrain';
        // const baseUrl = 'https://api.cybaseusa.com/'+ 'cybaseusa';

        /*************** Development ****************/
        // const baseUrl = 'https://api.cybaseuat.com/' + host;  //test-api
        // const baseUrl = 'https://api.cybaseuat.com/' + 'cybaseuae';  //test-api

        if (request.url === '/api/Login/SignIn' || request.url === '/api/Login/forgetPassword'
            || request.url === '/api/Login/resetPassword' || request.url === '/api/Login/VerifyEmployeeEmail'
            || request.url === '/api/Login/SendVerificationEmail') {

            /*************** Development ****************/
                // const baseUrl = 'https://api.cybaseuat.com/' + host;  //test-api
                // const baseUrl = 'https://api.cybaseuat.com/' + 'cybaseuae';  //test-api
                // const baseUrl = 'https://api.cybaseuat.com/' + 'ctuaeuae-entityuat';  //test-api
                // const baseUrl = 'https://api.digitusuat.com/' + 'cyberspace-entityuat';  //test-api
                const baseUrl = 'https://api.digitusuat.com/' + host;

            request = request.clone({
                url: `${baseUrl + request.url}`,
                setHeaders: {
                    Authorization: ''
                }
            });
        } else {
            const currentUser = JSON.parse(localStorage.getItem('userDetails'))

            /*************** Development ****************/
            // const baseUrl = 'https://api.cybaseuat.com';
            const baseUrl = 'https://api.digitusuat.com';

            request = request.clone({
                url: `${baseUrl + request.url}`,
                setHeaders: {
                    Authorization: `Bearer ${currentUser ? currentUser.access_token : ''}`
                }
            });
        }
        
        return next.handle(request).pipe(
            finalize(() => {
                this.loaderService.hide();
                if (this.timer) {
                    clearTimeout(this.timer);
                }
            }),
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        let isTokenExpired = this.authService.isTokenExpired();
                        if (isTokenExpired) {
                            this.toastr.error("Session time out, Kindly login again", "Error!");
                            this.authService.logout();
                        } else {
                            const userDetails = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')) : null;
                            if (userDetails && userDetails?.refresh_token) {
                                this.authService.refreshToken().subscribe((response: any) => {
                                    if (response.returnStatus && response.statusCode === 200) {
                                        localStorage.setItem("userDetails", JSON.stringify(response?.data));
                                        localStorage.setItem(
                                            "access_token",
                                            JSON.stringify(response.data.access_token)
                                        );
                                        this.loaderService.show();
                                        setTimeout(() => {
                                            window.location.reload();
                                            this.loaderService.hide();
                                        }, 500);
                                    }
                                }, (error) => {
                                    this.toastr.error('Session time out, Kindly login again', 'Error!');
                                    this.authService.logout();
                                });
                            }
                        }
                    }
                }
                this.loaderService.hide()
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                // const error = err.error.message || err.statusText;
                return throwError(err);
            }));
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            //   this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}